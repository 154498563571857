jQuery(document).ready(function($) {

  $('.wp-block-media-text__show-caption').each(function(){
    let caption = $(this).attr("data-caption");
    if(caption !== ""){
      $(this).append('<figcaption class="wp-element-caption">'+caption+'</figcaption>');
    }
  });

});


