jQuery(document).ready(function($) {

  $('.wp-block-cover__show-caption').each(function(){
    let caption = $(this).attr("data-caption");
    if(caption !== ""){
      $('<figcaption class="wp-element-caption">'+caption+'</figcaption>').insertAfter(this);
    }
  });

});


